import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import { SharedStateProvider } from "../components/SharedState/PageSharedState"
import SEO from "../components/seo"

const MakeAPayment = props => {
  const post = props.data.allUniquePagesJson.nodes[0]
  const language = "en"

  return (
    <SharedStateProvider>
      <Layout language={language}>
        <SEO
          lang={language}
          title={post.metaTitle}
          description={post.metaDescription}
          pathname={props.location.pathname}
        />

        <div className="columns top-section color-back make-a-payment">
          <div className="column is-4"></div>
          <div className="column">
            <h1>{post.heading}</h1>
            <p>{post.blurb}</p>
            <form
              target="_blank"
              name="PrePage"
              method="post"
              action=" https://Simplecheckout.authorize.net/payment/CatalogPayment.aspx "
            >
              {" "}
              <input
                type="hidden"
                name="LinkId"
                value="bdc9ec3e-ab4e-4c9f-974b-901a195d2ce3"
              />{" "}
              <input
                style={{ cursor: "pointer" }}
                className="standard-button contained"
                type="submit"
                value="Pay Now"
              />{" "}
            </form>
          </div>
          <div className="column is-4"></div>
        </div>
      </Layout>
    </SharedStateProvider>
  )
}

export const pageQuery = graphql`
  query MyQuery {
    allUniquePagesJson(filter: { title: { eq: "make-a-payment/" } }) {
      nodes {
        heading
        blurb
        metaDescription
        metaTitle
      }
    }
  }
`

export default MakeAPayment
